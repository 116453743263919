import { Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; 
import { useTheme, useMediaQuery } from '@mui/material';

const Support = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        container: {
            width: '100%',
            borderSizing: 'border-box',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
        },
        imageContainer: {
            paddingLeft: '5%',
            width: '40%',
        },
        buttonContainer: {
            display: 'flex',
            marginTop: 10
        },
        contactButton: {
            background: '#EA2024',
            color: 'white',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: '#d0191f',
                boxShadow: 'none'
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#EA2024',
            },
            padding: '15px 20px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
        },
    }

    return (
        <div style={styles.container}>
            <div style={styles.imageContainer}>
                 <img loading='lazy' src="/images/support-left-img.svg" alt="LEFT IMG" width={isMobile ? "340px" : "400px"} />
            </div>
            <div style={{ marginTop: isMobile ? 0 : '2%', paddingLeft: isMobile ? '25px' : '' }}>
                <h3 style={{ fontSize: isMobile ? 25 : '30px' }}>Do you have any questions?</h3>
                <h4>Our team will be happy to assist.</h4>
                <div style={styles.buttonContainer}>
                    <a href={'https://wa.link/wh0p7a'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}> {/* WhatsApp Support */}
                        <Button variant="contained" sx={styles.contactButton}>
                            Contact Us
                            <ArrowForwardIcon sx={{ marginLeft: '10px' }} />
                        </Button>
                    </a>
                </div>
                <h4 style={{ marginTop: '10%' }}>+(92)-339-4882800</h4>
            </div>
        </div>
    );
}

export default Support;
