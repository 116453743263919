import { Star } from '@mui/icons-material';
import { useTheme, useMediaQuery } from '@mui/material';

const Stats = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        container: {
            display: 'flex',
            flexDirection:isMobile ?'column': 'row',
            padding: isMobile ?'15px': '2% 10%',
            marginTop:isMobile?'10%': '5%',
            background: '#FFFFFF',
            overflow: 'hidden',
        },
        imageContainer: {
            width: '40%',
        },
        contentContainer: {
            width:isMobile?'100%': '60%',
            paddingLeft: '10px',
        },
        heading: {
            textAlign: 'left',
            fontSize: isMobile ? 20 : '24px',
            fontWeight: '600',
            marginBottom: '40px',
        },
        boxContainer: {
            marginTop: '7%',
            width: '100%',
            display: 'grid',
            gridTemplateColumns:isMobile ?'repeat(1, auto)': 'repeat(2, auto)',
            gap: '30px',
        },
        box: {
            width: isMobile?'85%': '300px',
            border: '1px solid black',
            borderRadius: '20px',
            padding: '15px',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '500',
        },
        boxHeading: {
            fontSize: '32px',
            color: '#EA2024',
            marginTop: '0',
            marginBottom: '5px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.imageContainer}>
                 <img loading='lazy' src="/images/right-img.svg" alt="Right Computer img" width={isMobile ? '345px' : "400px"} height="500px" />
            </div>
            <div style={styles.contentContainer}>
                <h3 style={{ ...styles.heading, fontWeight: 600, }}>
                Partner with  <span style={{ color: '#EA2024' }}>Buzz Filing</span> for your success.
                </h3>
                <div style={styles.boxContainer}>
                    <div style={styles.box}>
                        <h3 style={styles.boxHeading}>800+</h3>
                        <div>Happy Customers</div>
                    </div>
                    <div style={styles.box}>
                        <h3 style={styles.boxHeading}>100%</h3>
                        <div>Service Delivery</div>
                    </div>
                    <div style={styles.box}>
                        <h3 style={styles.boxHeading}>5 </h3>
                        <div>Customer Rating </div>
                    </div>
                    <div style={styles.box}>
                        <h3 style={styles.boxHeading}>3+</h3>
                        <div>Active Years</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stats;
