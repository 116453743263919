import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';


const Testimonials = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
             <img loading='lazy'
                src="/images/right-arrow.svg"
                alt="Next"
                style={{ ...style, left: isMobile ? '85%' : '64%', width: '40px', height: '50px', transform: 'rotate(0deg)', cursor: 'pointer', marginTop: isMobile ? '35%' : '35%' }}
                onClick={onClick}
                className={className}
            />
        );
    };

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
             <img loading='lazy'
                src="/images/right-arrow.svg"
                alt="Previous"
                style={{ ...style, left: isMobile ? '50%' : '56%', width: '40px', height: '50px', transform: 'rotate(180deg)', cursor: 'pointer', marginTop: '35%' }}
                onClick={onClick}
                className={className}
            />
        );
    };

    const styles = {
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginBottom: '5%',
            marginTop: isMobile ? '10%' : ''
        },
        heading: {
            width: isMobile ? '100%' : '900px',
            fontSize: isMobile ? '24px' : '50px',
            fontWeight: '600',
            textAlign: 'center',
            color: 'black',
            margin: isMobile ? '15px 0' : '20px',
        },
        heading2: {
            fontSize: isMobile ? '10px' : '12px',
            textAlign: 'center',
            margin: '0',
        },
        testimonialContainer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : '',
            alignItems: 'center',
            width: '100%',
            padding: '0 5%',
            boxSizing: 'border-box',
        },
        leftImageContainer: {
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        sliderContainer: {
            width: isMobile ? '100%' : '50%',
            paddingRight: isMobile ? '5%' : '10%',
            boxSizing: 'border-box',
            position: 'relative',
        },
        testimonialItem: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            padding: isMobile ? '3%' : '2%',
            boxSizing: 'border-box',
            border: '2px solid black',
            borderRadius: '10px',
            position: 'relative',
            width: isMobile ? '100%' : '80%',
            margin: '60px 0 0',
            marginTop: isMobile ? '40%' : '30%',
            background: 'white',
        },
        testimonialText: {
            fontSize: '15px',
            color: 'black',
            marginBottom: '20px',
            marginTop: '20px',
        },
        testimonialAuthor: {
            fontWeight: '600',
            fontSize: '18px',
            color: 'black',
        },
    };

    const testimonials = [
        {
            image: '/images/face1.jfif',
            name: 'Mohsin Ahmed',
            role: 'Amazon Seller',
            text: 'Must recommended. Very good and positive response from buzzs team. not a paid review.',
        },
        {
            image: '/images/face2.jfif',
            name: 'Yasir Rajput',
            role: 'Agency Owner',
            text: 'I work closely with Buzz Filling, and the team is incredibly cooperative. I highly recommend working with Buzz Filling without any hesitation.',
        },
        {
            image: '/images/face3.jfif',
            name: 'Leshpets',
            role: 'Amazon Seller',
            text: 'Highly highly recommended.. The team is extremely cooperative.. They deal all paper work for my LLC very professionally and nicely.. All my questions are answered so nicely.',
        },
        {
            image: '/images/face4.webp',
            name: 'Qurat Ul Ain',
            role: 'Agency Owner',
            text: 'Excellent company formation services! The process was seamless, and their team was knowledgeable, guiding me through every step efficiently. Highly recommended for anyone looking to start a business quickly and hassle-free.',
        },
        {
            image: '/images/face5.webp',
            name: 'Abdul Sattar',
            role: 'Agency Owner',
            text: 'It was a great experiene getting help from Buzz Filing team to form LLC. They are professional, cooperative and very friendly, that makes them a good team. They offered great support whole time and always ready to help you with any queries. I would highly recommend their services',
        }
    ];


    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    return (
        <div style={{ overflow: 'hidden', width: "100%", marginBottom: isMobile ? 0 : '5%', boxSizing: 'border-box' }}>
            <div style={styles.titleContainer}>
                <h2 style={{ color: '#EA2024' }}>Testimonials</h2>
                <h2 style={styles.heading}>Learn about Buzz Filing from entrepreneurs.</h2>
                <div style={styles.heading2}>Check out the experiences of entrepreneurs to learn more about Buzz Filing.</div>
            </div>
            <div style={styles.testimonialContainer}>
                <div style={styles.leftImageContainer}>
                     <img loading='lazy' src="/images/left-img.svg" alt="Left img" width={isMobile ? '300px' : '450px'} />
                </div>
                <div style={styles.sliderContainer}>
                    <Slider {...sliderSettings}>
                        {testimonials?.map((testimonial, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', paddingTop: isMobile ? '20px' : '' }}>
                                     <img loading='lazy'
                                        src={testimonial.image}
                                        alt="Face IMG"
                                        style={{
                                            right: '5%',
                                            marginBottom: '5%',
                                            borderRadius: '50%', // Makes it round like an avatar
                                            width: isMobile ? '200px' : '100px',  // Adjust width and height for mobile and desktop
                                            height: isMobile ? '100px' : '100px'
                                        }}
                                    />
                                     <img loading='lazy' src="/images/Vector.svg" alt="Quotes" style={{ marginLeft: isMobile ? '80%' : '100%' }} width={isMobile ? '25%' : ''} />
                                </div>
                                <Box style={styles.testimonialItem}>
                                    <h2 style={{ color: "#EA2024" }}>{testimonial.name}</h2>
                                    <div>{testimonial.role}</div>
                                    <p style={styles.testimonialText}>{testimonial.text}</p>
                                </Box>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
