import { useTheme, useMediaQuery } from '@mui/material';

const testimonials = [
    {
        name: "Sadat Attique",
        profession: "TDG Supply LLC",
        country: "Pakistan",
        stars: 5,
        videoUrl: "https://www.youtube.com/embed/KIn4rnL4V44?controls=0&modestbranding=1&rel=0&showinfo=0",
    },
    {
        name: "Usman",
        profession: "Metro Holdings LLC",
        stars: 5,
        videoUrl: "https://www.youtube.com/embed/7Vwv2o8JvQ0?controls=0&modestbranding=1&rel=0&showinfo=0",
    },
    {
        name: "Umer Saeed",
        profession: "Digital Nomads LLC",
        country: "Pakistan",
        stars: 5,
        videoUrl: "https://www.youtube.com/embed/c-hLXHfIDSs?controls=0&modestbranding=1&rel=0&showinfo=0",
    },
    {
        name: "Mujahid",
        profession: "Lesh LLC",
        country: "Pakistan",
        stars: 5,
        videoUrl: "https://www.youtube.com/embed/RGtfEVWkztY?controls=0&modestbranding=1&rel=0&showinfo=0",
    },
    {
        name: "Khakan Hayder",
        profession: "Vconekt LLC",
        country: "Pakistan",
        stars: 5,
        videoUrl: "https://www.youtube.com/embed/mYPxKBLu53c",
    },
];

const WorldWideTestimonials = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        container: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px',
            padding: '20px',
            overflowX: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
        },
        card: {
            flex: '0 0 auto',
            width: isMobile ? '95%' : '250px',
            borderRadius: '10px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            background: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '15px',
            objectFit: 'contain',
            transition: 'height 0.3s ease',
        },
        video: {
            width: '100%',
            height: '400px',
            borderRadius: '10px',
            marginBottom: '10px',
        },
        name: {
            fontWeight: 'bold',
            fontSize: '16px',
            textAlign: 'center',
        },
        profession: {
            fontSize: '14px',
            color: '#888',
            textAlign: 'center',
            marginBottom: '10px',
        },
        stars: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '5px',
            color: '#f5c518',
        },
        heading: {
            width: isMobile ? '95%' : '70%',
            margin: 'auto',
            marginTop: isMobile ? '10%' : '7%',
            fontSize: isMobile ? '25px' : '45px',
            color: '#EA2024',
            textAlign: 'center',
        }
    };

    const renderStars = (count) => {
        return '★'.repeat(count).padEnd(5, '☆');
    };

    return (
        <div>
            <h3 style={styles.heading}>
                Experience Excellence Through Customer Stories.
            </h3>
            <div style={styles.container}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} style={styles.card}>
                        <iframe
                            style={styles.video}
                            src={testimonial.videoUrl}
                            title={testimonial.name}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <div style={styles.stars}>{renderStars(testimonial.stars)}</div>
                        <div style={styles.name}>{testimonial.name}</div>
                        <div style={styles.profession}>
                            {testimonial.profession}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WorldWideTestimonials;
