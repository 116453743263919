import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const Comparison = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const styles = {
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            boxSizing: 'border-box',
            padding: isMobile ? '2% 2% 10%' : '1% 3% 7%',
        },
        table: {
            width: isMobile ? '100%' : '1100px',
            borderCollapse: 'collapse',
            textAlign: 'center',
            border: 'none',
            backgroundColor: '#fff',
        },
        th: {
            border: '1px solid white',
            padding: isMobile ? '5%' : '2.5%',
            backgroundColor: '#f9f9f9',
            fontWeight: '600',
            fontSize: isMobile ? '12px' : '16px',
            color: 'black',
        },
        thBuzzFiling: {
            backgroundColor: 'black',
            color: 'white',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
        },
        td: {
            borderBottom: '1px solid grey',
            padding: isMobile ? '5%' : '2.5%',
            fontSize: isMobile ? '12px' : '14px',
        },
        column1: {
            color: 'black',
        },
        column2: {
            backgroundColor: 'black',
            color: 'white',
            padding: isMobile ? '10px' : '20px',
            borderRadius: '0px',
        },
        column2Total: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        totalRow: {
            fontWeight: '600',
            backgroundColor: '#f9f9f9',
        },
        image: {
            width: isMobile ? '15px' : '20px',
            height: isMobile ? '15px' : '20px',
        },
        totalText: {
            color: 'white',
        },
        totalBold: {
            fontWeight: '600',
        },
        blackBold: {
            color: 'black',
            fontWeight: '600',
        },
    };

    const rowContent = [
        ['Company Formation'],
        ['EIN'],
        ['Registered Agent'],
        ['Business Address'],
        ['U.S. Bank Account'],
        ['U.S. Phone Number'],
        ['FinCEN BOI Report'],
        ['Digital Document Access'],
        ['Total', '$149 + State Fee', '$349 + State Fee', '$349 + State Fee'],
    ];

    const renderRow = (rowIndex) => {
        const isImageRow = rowIndex < 8; // Rows for services will have images (check/cross icons)
        const iconSrc = {
            buzzFiling: '/images/check-icon.svg',
            column3: 
                (rowIndex >= 5 && rowIndex <= 7)  // Rows 5, 6, and 7 for Others column
                    ? '/images/cross-icon.svg' 
                    : '/images/check-icon.svg',
            column4: 
                (rowIndex >= 5 && rowIndex <= 7)  // Rows 5, 6, and 7 for Others column
                    ? '/images/cross-icon.svg' 
                    : '/images/check-icon.svg',
        };

        return (
            <tr key={rowIndex}>
                <td style={{ ...styles.td, ...styles.column1, ...(rowIndex === 8 && styles.totalBold) }}>
                    {rowContent[rowIndex][0]}
                </td>
                <td style={{ 
                        ...styles.td, 
                        ...styles.column2, 
                        ...(rowIndex === 8 && styles.column2Total)  
                    }}>
                    {isImageRow ? (
                         <img loading='lazy' src={iconSrc.buzzFiling} alt="icon" style={styles.image} />
                    ) : (
                        <span style={styles.totalText}>{rowContent[rowIndex][1]}</span>
                    )}
                </td>
                <td style={{ ...styles.td, ...(rowIndex === 8 && styles.blackBold) }}>
                    {isImageRow ? (
                         <img loading='lazy' src={iconSrc.column3} alt="icon" style={styles.image} />
                    ) : (
                        rowContent[rowIndex][2]
                    )}
                </td>
                <td style={{ ...styles.td, ...(rowIndex === 8 && styles.blackBold) }}>
                    {isImageRow ? (
                         <img loading='lazy' src={iconSrc.column4} alt="icon" style={styles.image} />
                    ) : (
                        rowContent[rowIndex][3]
                    )}
                </td>
            </tr>
        );
    };

    return (
        <div style={styles.container}>
            <h2 style={{ textAlign: 'center', marginBottom: isMobile ? '30px' : '50px', color:'#EA2024' }}>COMPARISON</h2>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={{ ...styles.th }}>Comparison</th>
                        <th style={{ ...styles.th, ...styles.thBuzzFiling }}>Buzz Filing</th>
                        <th style={styles.th}>Ejad Plus</th>
                        <th style={styles.th}>Befiler</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.from({ length: 8 }, (_, i) => renderRow(i))}
                    {renderRow(8)} {/* Total row */}
                </tbody>
            </table>
        </div>
    );
};

export default Comparison;
