import React from 'react';
import { Paper } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';

const Servicesab = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    

    const styles = {
        container: {
            width: '100%',
            marginTop: isMobile ? '0' : '3%',
            boxSizing: 'border-box',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isMobile ? '0 10px' : '',
        },
        paperContainer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '10px' : '20px',
            marginTop: isMobile ? '8%' : '5%',
            padding: '0 5%',
            justifyContent: 'center',
        },
        paper: {
            width: isMobile ? '330px' : '280px',
            textAlign: 'left',
            padding: '20px',
            background: 'linear-gradient(180deg, #F9F9F9 0%, #F8F8F8 100%)',
            borderRadius: isMobile ? '10px' : '20px',
            boxShadow: '0px 14px 24px -15px #00000040',
            height: isMobile ? '170px' : '',
            gap: isMobile ? '20px' : '',
        },
        title: {
            marginTop: isMobile ? '3%' : '5%',
            color: '#EA2024',
            fontSize: isMobile ? '13px' : '20px',
            fontWeight: 600,
        },
        description: {
            fontSize: isMobile ? '13px' : '16px',
            fontWeight: 300,
            marginTop: '5%',
            marginBottom: isMobile ? '1%' : '5%',
        },
        aboutContainer: {
            textAlign: 'center',
            marginTop: '5%',
            padding: isMobile ? '0 10%' : '0 5%',
        },
        aboutTitle: {
            color: '#EA2024',
            fontSize: isMobile ? '20px' : '28px',
            fontWeight: 600,
        },
        aboutDescription: {
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: 300,
            marginTop: '1%',
            lineHeight: '1.5',
        },
    };

    return (
        <div style={styles.container}>
            <div>
                <h3 style={{ color: '#EA2024', fontSize: isMobile ? '20px' : '34px', textAlign: 'center' }}>About Buzz                </h3>
                <div style={{ marginTop: 0, fontSize: isMobile ? '20px' : '50px', fontWeight: 600, textAlign: 'center' }}>Your Trusted Partner in Business Formation                </div>
                <div style={{ marginTop: '3%', fontSize: isMobile ? '13px' : '18px', fontWeight: 400, textAlign: 'center' }}>We offer seamless support for new and expanding businesses to ensure your success.
                </div>
            </div>
            <div style={styles.paperContainer}>
                <Paper style={styles.paper}>
                      <img loading='lazy' src="/images/Paper Plane.png" alt="Half colored img" width={isMobile ? '18%' : ''} />
                    <div style={styles.title}>Streamlined Process                    </div>
                    <div style={styles.description}>
                    We make business formation quick and easy, so you can start your venture without delay.
                    </div>
                </Paper>
                <Paper style={styles.paper}>
                     <img loading='lazy' src="/images/Paper Plane.png" alt="Half colored img" width={isMobile ? '18%' : ''} />
                    <div style={styles.title}>Tailored Solutions                    </div>
                    <div style={styles.description}>
                    Our services are customized to fit the specific needs of your business, ensuring you get exactly what you require.
                    </div>
                </Paper>
                <Paper style={styles.paper}>
                     <img loading='lazy' src="/images/Paper Plane.png" alt="Half colored img" width={isMobile ? '18%' : ''} />
                    <div style={styles.title}>Ongoing Support                    </div>
                    <div style={styles.description}>
                    We offer continuous assistance even after your business is set up, helping you navigate future challenges.
                    </div>
                </Paper>
                <Paper style={styles.paper}>
                     <img loading='lazy' src="/images/Paper Plane.png" alt="Half colored img" width={isMobile ? '18%' : ''} />
                    <div style={styles.title}>Expert Resources
                    </div>
                    <div style={styles.description}>
                    Access a wealth of knowledge and tools designed to help you thrive in your industry and stay compliant.                    </div>
                </Paper>
            </div>
           
        </div>
    );
};

export default Servicesab;
