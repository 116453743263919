import { FaCheck } from 'react-icons/fa';
import { useTheme, useMediaQuery } from '@mui/material';

const Process = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const styles = {
        container: {
            width: "100%",
            marginBottom: '5%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isMobile ? '0 20px' : '0 40px',
        },
        titleContainer: {
            textAlign: 'center',
            marginBottom: '5%',
        },
        heading: {
            fontSize: isMobile ? '28px' : isTablet ? '35px' : '40px',
            fontWeight: '600',
            color: 'black',
            margin: '20px 0',
        },
        heading2: {
            fontSize: isMobile ? '13px' : '16px',
            marginBottom: isMobile ? '8%' : '',
        },
        boxShadowContainer: {
            width: isMobile ? '85%' : '70%',
            margin: '0 auto',
            padding: isMobile ? '20px' : '20px 50px 30px 50px',
            boxShadow: '0px 0px 10px 0px #EA202440',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            marginBottom: '2%',
            borderRadius: '10px',
            backgroundColor: '#fff',
        },
        imageContainer: {
            alignSelf: isMobile ? 'center' : 'flex-start',
            marginRight: isMobile ? '0' : '30px',
            marginBottom: isMobile ? '20px' : '0',
        },
        stepImage: {
            width: isMobile ? '100%' : isTablet ? '70%' : 'auto',
            maxWidth: '100%',
            height: 'auto',
        },
        contentContainer: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            textAlign: isMobile ? 'center' : 'left',
        },
        description: {
            fontWeight: isMobile ? '300' : '400',
            color: '#666',
            fontSize: '14px',
            marginBottom: '10px',
        },
        timeline: {
            fontSize: isMobile ? '13px' : '15px',
            marginTop: isMobile ? '10%' : '3%',
            color: '#000000',
        },
        planItemsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: isMobile ? 'center' : 'flex-start',
            marginTop: '20px',
        },
        planItem: {
            display: 'flex',
            alignItems: 'center',
            textAlign: isMobile ? 'left' : '',
            width: isMobile ? '100%' : '48%',
            marginBottom: '10px',
        },
        planText: {
            flex: 1,
            fontSize: isMobile ? '14px' : '16px',
        },
        planIcon: {
            width: '20px',
            height: '20px',
            backgroundColor: '#EA2024',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            marginRight: '10px',
            fontSize: '12px',
        },
    };

    const steps = [
        {
            stepNumber: "Step 1",
            title: "Sign Up",
            description: "Sign up on our online platform. Our whole process is online.",
            timeline: "15 minutes",
            image: "/images/step1-img.svg",
            planItems: [],
        },
        {
            stepNumber: "Step 2",
            title: "State Filing",
            description: "We’ll do all your state filings document preparation and filing. No additional fees.",
            timeline: "1 day",
            image: "/images/step2-img.svg",
            planItems: [
                "Detailed Company Name Check",
                "All forms Preparation",
                "E-filing of Documents",
                "All State Fees and Registered Agent fees",
            ],
        },
        {
            stepNumber: "Step 3",
            title: "EIN",
            description: "We’ll get the EIN from IRS which is required for your bank account opening.",
            timeline: "5 to 7 business days",
            image: "/images/step3-img.svg",
            planItems: [
                "Preparation of SS4 Document",
                "Digital Signatures",
                "Fax or E-file with IRS",
                "Follow-up with IRS",
            ],
        },
        {
            stepNumber: "Step 4",
            title: "Bank Account",
            description: "We’ll partner with the bank and help you open your business bank account.",
            timeline: "5 business days",
            image: "/images/step2-img.svg",
            planItems: [
                "Mercury Banking",
                "Payoneer",
                "Wise",
                "Stripe",
            ],
        }
    ];

    return (
        <div style={styles.container}>
            <div style={styles.titleContainer}>
                <h2 style={{ color: '#EA2024', textTransform: 'uppercase' }}>PROCESS</h2>
                <h2 style={styles.heading}>Buzz Filing's Business Formation Process 
                </h2>
                <div style={styles.heading2}>
                Follow our straightforward steps to start your business with ease.                </div>
            </div>
            {steps.map((step, index) => (
                <div key={index} style={styles.boxShadowContainer}>
                    <div style={styles.imageContainer}>
                         <img loading='lazy'
                            src={step.image}
                            alt={step.title}
                            style={styles.stepImage}
                        />
                    </div>
                    <div style={styles.contentContainer}>
                        <h4>{step.stepNumber}</h4>
                        <h3 style={{ color: '#EA2024', marginTop: '0' }}>{step.title}</h3>
                        <div style={styles.description}>{step.description}</div>
                        <div style={styles.timeline}>Time Line: {step.timeline}</div>
                        {step.planItems.length > 0 && (
                            <div style={styles.planItemsContainer}>
                                {step.planItems.map((item, i) => (
                                    <div key={i} style={styles.planItem}>
                                        <div style={styles.planIcon}><FaCheck /></div>
                                        <div style={styles.planText}>{item}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Process;
